import useWebsiteContext from "framework/hooks/useWebsiteContext"
import ContentPage from "framework/pages/ContentPage"
import React from "react"
import { Route } from "react-router-dom"

const Routes = () => {
  const { pages } = useWebsiteContext()

  return (
    <>
      <Route path="/" key="root" exact component={ContentPage} />
      {Object.entries(pages).map(([path, component]) => (
        <Route key={path} path={`/${path}`} exact component={component} />
      ))}
    </>
  )
}

export default Routes
