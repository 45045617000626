import USENCopy from "copy/en_us"
import HUHUCopy from "copy/hu_hu"
import TermsPage from "framework/pages/TermsPage"
import "fonts.css"
import GlobalContent from "./content/global"
import PrivacyPolicyPage from "framework/pages/PrivacyPolicyPage"
import AFAFCopy from "./copy/af_af"

export const auth = {
  enabled: false,
}

export const content: Content = {
  global: GlobalContent,
}

export const copy: Copy = {
  en: USENCopy,
  hu: HUHUCopy,
  af: AFAFCopy,
}

export const english = {
  label: "English",
  locale: "en",
}
export const hungarian = {
  label: "Magyar",
  locale: "hu",
}

export const afrikaans = {
  label: "Afrikaans",
  locale: "af",
}

export const i18n = {
  contentLocales: undefined,
  languages: [afrikaans, english, hungarian],
}

export const options: WebsiteOptions = {
  defaultContentLocale: "global",
  defaultLanguage: english,
  googleAnalyticsId: "G-HN1TR6HHL7",
  logo: "novartis.png",
  welcomePage: true,
}

export const pages = {
  terms: TermsPage,
  privacy: PrivacyPolicyPage,
}

const website: Website = {
  auth,
  content,
  copy,
  i18n,
  options,
  pages,
}

export default website
