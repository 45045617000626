const GlobalContent: ContentSection = {
  items: [
    {
      id: "bad-reception",
      source: "xPfy15-4k6Y",
      thumbnail: "images/thumbs/bad-reception.jpg",
    },
    {
      id: "baking",
      source: "jH6fs2TLP8k",
      thumbnail: "images/thumbs/baking.jpg",
    },
    {
      id: "campfire",
      source: "FyeSYtTKaL0",
      thumbnail: "images/thumbs/campfire.jpg",
    },
    {
      id: "flying-lessons",
      source: "w28j2HcWfL8",
      thumbnail: "images/thumbs/flying-lessons.jpg",
    },
    {
      id: "garage-band",
      source: "vExR7eG3ug4",
      thumbnail: "images/thumbs/garage-band.jpg",
    },
    {
      id: "hangout-spot",
      source: "1_sicLEGC_w",
      thumbnail: "images/thumbs/hangout-spot.jpg",
    },
    {
      id: "hungry",
      source: "1yVZAEN3K_Y",
      thumbnail: "images/thumbs/hungry.jpg",
    },
    {
      id: "message",
      source: "fPdCoUo_4sk",
      thumbnail: "images/thumbs/message.jpg",
    },
    {
      id: "neighbor",
      source: "pZ5wsVYTly4",
      thumbnail: "images/thumbs/neighbor.jpg",
    },
    {
      id: "nice-day",
      source: "df3vZl7dpP0",
      thumbnail: "images/thumbs/nice-day.jpg",
    },
    {
      id: "record",
      source: "rjOGnHUeyc4",
      thumbnail: "images/thumbs/record.jpg",
    },
    {
      id: "snow-clone",
      source: "BOOga74yUZo",
      thumbnail: "images/thumbs/snow-clone.jpg",
    },
    {
      id: "switch",
      source: "MI5cozVmMGU",
      thumbnail: "images/thumbs/switch.jpg",
    },
    {
      id: "wishing-well",
      source: "alFI87OYk94",
      thumbnail: "images/thumbs/wishing-well.jpg",
    },
    {
      id: "workout",
      source: "nMnmtpXaUDA",
      thumbnail: "images/thumbs/workout.jpg",
    },
  ],
}

export default GlobalContent
