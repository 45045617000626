import { english, hungarian, afrikaans } from "website"

type LangMap = {
  [key: string]: Language
}

const LANG_MAP: LangMap = {
  en: english,
  "en-US": english,
  hu: hungarian,
  "hu-HU": hungarian,
  af: afrikaans,
  "af-AF": afrikaans,
  "af-ZA": afrikaans,
  "af-NA": afrikaans,
}

export const detectLanguage = (website: Website) => {
  const browserLanguage = window.navigator.language

  return LANG_MAP[browserLanguage] || website.options.defaultLanguage
}
