import { AppBar, IconButton, makeStyles, Toolbar } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined"
import React from "react"
import useWebsiteOptions from "framework/hooks/useWebsiteOptions"
import { ReactComponent as Logo } from "assets/logo.svg"
import useWebsiteContext from "framework/hooks/useWebsiteContext"
import { isDesktop } from "theme"

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    justifyContent: "space-between",
  },
  logo: {
    width: 147,
  },
}))
const TopBar = () => {
  const classes = styles()
  const { logo } = useWebsiteOptions()
  const { toggleMobileMenu, toggleLanguageMenu } = useWebsiteContext()

  if (isDesktop()) {
    return null
  }

  return (
    <AppBar color="default" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <IconButton edge="start" onClick={toggleMobileMenu}>
          <MenuIcon />
        </IconButton>
        {logo && <Logo className={classes.logo} />}
        <IconButton edge="end" onClick={toggleLanguageMenu}>
          <LanguageOutlinedIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
