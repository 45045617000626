import { makeStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import YouTube, { Options } from "react-youtube"

const styles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingBottom: "56.25%",
    position: "relative",
  },
  youtube: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
  },
}))

const youtubeOptions: Options = {
  playerVars: {
    autoplay: 0,
    rel: 0,
  },
}

type Props = {
  item: ContentItem
}

const Video = ({ item }: Props) => {
  const classes = styles()
  const [initialItem] = useState<ContentItem>(item)
  const [player, setPlayer] = useState<undefined | any>(undefined)

  useEffect(() => {
    if (player && initialItem.id !== item.id) {
      player.playVideo()
    }
  }, [item, player, initialItem])

  return (
    <div className={classes.root}>
      <YouTube
        videoId={item.source}
        opts={youtubeOptions}
        onReady={(event) => {
          setPlayer(event.target)
        }}
        onPlay={() => {
          gtag("event", "content_viewed", {
            content_id: item.id,
          })
        }}
        className={classes.youtube}
      />
    </div>
  )
}

export default Video
