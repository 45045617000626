import { CssBaseline, makeStyles, MuiThemeProvider } from "@material-ui/core"
import Layout from "framework/Layout"
import Routes from "framework/Routes"
import { detectLanguage } from "framework/utils/language"
import React, { createContext, useEffect, useState } from "react"
import { BrowserRouter as Router } from "react-router-dom"
import theme from "theme"
import RequiresAuthentication from "../RequiresAuthentication"
import RequiresNoAuthentication from "../RequiresNoAuthentication"
import RequiresNoWelcomePage from "../RequiresNoWelcomePage"
import RequiresWelcomePage from "../RequiresWelcomePage"

const WELCOME_COMPLETED_KEY = "welcomePageCompleted"

export const WebsiteContext = createContext<WebsiteContext | undefined>(undefined)

const styles = makeStyles({
  "@global": {
    body: {
      margin: 0,
      scrollBehavior: "smooth",
    },
    a: {
      "&:visited, &:-webkit-any-link": {
        color: "inherit",
      },
    },
  },
})

const copyForLanguage = (copy: Copy, language: Language) => {
  if (copy[language.locale]) {
    return copy[language.locale]
  } else {
    throw new Error(`No copy found for locale: ${language.locale}`)
  }
}

type Props = {
  website: Website
}

const Website = ({ website }: Props) => {
  const classes = styles()

  // I18n
  const [contentLocale, setContentLocale] = useState<string>(website.options.defaultContentLocale)
  const [language, setLanguage] = useState<Language>(detectLanguage(website))

  // UI
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false)
  const [languageMenuOpen, setLanguageMenuOpen] = useState<boolean>(false)
  const [background, setBackground] = useState<string>("primary")

  // User-driven
  const [welcomePageCompleted, setWelcomePageCompleted] = useState<boolean>(
    !!window.sessionStorage.getItem(WELCOME_COMPLETED_KEY)
  )
  const [activeContentItem, setActiveContentItem] = useState<ContentItem>(
    website.content[contentLocale].items[0]
  )

  // Set the 1st content item as active by default
  useEffect(() => {
    const { content } = website
    const { items } = content[contentLocale]
    setActiveContentItem(items[0])
  }, [setActiveContentItem, contentLocale, website])

  // Track completion of the welcome page
  useEffect(() => {
    if (welcomePageCompleted) {
      window.sessionStorage.setItem(WELCOME_COMPLETED_KEY, "true")
    } else {
      window.sessionStorage.removeItem(WELCOME_COMPLETED_KEY)
    }
  }, [welcomePageCompleted])

  const { content, options, copy, pages, i18n } = website

  const localizedCopy = copyForLanguage(copy, language)

  // Set site title based on localized copy
  useEffect(() => {
    document.title = localizedCopy.layout.title
  }, [localizedCopy])

  // Track locale in GA
  useEffect(() => {
    gtag("set", {
      language: language.locale,
    })
    console.log(`lang set to ${language.locale}`)
  }, [language])

  const value = {
    isAuthenticated: false,
    welcomePageCompleted,
    setWelcomePageCompleted,
    contentLocale,
    setContentLocale,
    language,
    setLanguage,
    content,
    copy: localizedCopy,
    options,
    pages: pages || {},
    mobileMenuOpen,
    toggleMobileMenu: () => setMobileMenuOpen(!mobileMenuOpen),
    languageMenuOpen,
    toggleLanguageMenu: () => setLanguageMenuOpen(!languageMenuOpen),
    i18n,
    background,
    setBackground,
    activeContentItem,
    setActiveContentItem,
  }

  const AuthContainer = website.auth.enabled ? RequiresAuthentication : RequiresNoAuthentication
  const WelcomeContainer = website.options.welcomePage ? RequiresWelcomePage : RequiresNoWelcomePage

  return (
    <WebsiteContext.Provider value={value}>
      <MuiThemeProvider theme={theme}>
        <AuthContainer>
          <WelcomeContainer>
            <CssBaseline classes={{ "@global": classes["@global"] }} />
            <Router>
              <Layout>
                <Routes />
              </Layout>
            </Router>
          </WelcomeContainer>
        </AuthContainer>
      </MuiThemeProvider>
    </WebsiteContext.Provider>
  )
}

export default Website
