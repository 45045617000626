import React from "react";

const SignInPage = () => {
  return (
    <div>
      <h1>Please sign in</h1>
      <button>TEST1</button>
    </div>
  );
};

export default SignInPage;
