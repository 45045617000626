import { Drawer, List, ListItem, ListItemText, makeStyles } from "@material-ui/core"
import useWebsiteContext from "framework/hooks/useWebsiteContext"
import React from "react"

const styles = makeStyles({
  root: {},
  paper: {
    top: 56,
  },
  backdrop: {
    top: 56,
    zIndex: 1099,
  },
  modal: {
    zIndex: 1099,
  },
})

const LanguageDrawer = () => {
  const { i18n, languageMenuOpen, setLanguage, toggleLanguageMenu } = useWebsiteContext()
  const classes = styles()

  return (
    <Drawer
      BackdropProps={{ className: classes.backdrop }}
      ModalProps={{ classes: { root: classes.modal } }}
      variant="temporary"
      anchor="top"
      open={languageMenuOpen}
      onClose={toggleLanguageMenu}
      className={classes.root}
      classes={{
        paperAnchorTop: classes.paper,
      }}
    >
      <List>
        {i18n.languages?.map((language) => (
          <ListItem
            button
            key={language.locale}
            onClick={() => {
              setLanguage(language)
              toggleLanguageMenu()
            }}
          >
            <ListItemText>{language.label}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}

export default LanguageDrawer
