import { Container, Typography } from "@material-ui/core"
import useWebsiteContext from "framework/hooks/useWebsiteContext"
import React, { useEffect } from "react"

const PrivacyPolicyPage = () => {
  const { setBackground } = useWebsiteContext()
  useEffect(() => {
    setBackground("secondary")
    return () => setBackground("primary")
  }, [setBackground])

  return (
    <Container>
      <Typography variant="h1">Privacy Policy</Typography>
    </Container>
  )
}

export default PrivacyPolicyPage
