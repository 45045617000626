import React from "react";
import useAuth from "../hooks/useAuth";
import SignInPage from "../pages/SignInPage";

type Props = {
  children: React.ReactNode;
};

const RequiresAuthentication = ({ children }: Props) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return <SignInPage />;
};

export default RequiresAuthentication;
