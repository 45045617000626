import useWebsiteContext from "./useWebsiteContext"

const useContent = () => {
  const ctx = useWebsiteContext();
  if(ctx.content[ctx.contentLocale]) {
    return ctx.content[ctx.contentLocale]
  }
  
  throw new Error(`Content locale not found in content definitions: ${ctx.contentLocale}`)
}

export default useContent;