import { Box, Button, Container, Grid, makeStyles, Typography } from "@material-ui/core"
import useCopy from "framework/hooks/useCopy"
import useWebsiteOptions from "framework/hooks/useWebsiteOptions"
import React, { useEffect } from "react"
import useWebsiteContext from "../../hooks/useWebsiteContext"
import { ReactComponent as Logo } from "assets/logo.svg"
import { ReactComponent as WelcomeGraphic } from "assets/welcome.svg"

const styles = makeStyles(
  (theme) => ({
    root: {},
    background: {
      backgroundImage: "url(images/bg-welcome-phone.png)",
      backgroundSize: "cover",
      backgroundPosition: "50% 100%",
      backgroundRepeat: "no-repeat",
      height: 264,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        backgroundImage: "url(images/bg-welcome-tablet.png)",
      },
      [theme.breakpoints.up("lg")]: {
        backgroundImage: "url(images/bg-welcome-desktop.png)",
        backgroundPosition: "0 50%",
        height: "100vh",
        position: "absolute",
        right: 0,
        top: 0,
        width: "50%",
      },
    },
    contentContainer: {},
    content: {
      alignItems: "center",
      justifyContent: "center",
      padding: "24px 0",
      [theme.breakpoints.up("lg")]: {
        justifyContent: "flex-start",
        height: "100vh",
      },
    },
    logo: {
      display: "block",
      padding: "16px 0",
      [theme.breakpoints.up("lg")]: {
        width: 270,
        height: 44,
      },
    },
  }),
  {
    name: "JumoWelcomePage",
  }
)

const WelcomePage = () => {
  const { setWelcomePageCompleted } = useWebsiteContext()
  const handleComplete = () => {
    setWelcomePageCompleted(true)
  }

  const { welcomePage: copy } = useCopy()
  const { logo } = useWebsiteOptions()
  const classes = styles()

  useEffect(() => {
    gtag("event", "page_view", {
      page_title: "welcome",
    })
  }, [])

  return (
    <Box className={classes.root}>
      <Box className={classes.background} />
      <Container className={classes.contentContainer}>
        <Grid container className={classes.content}>
          <Grid item md={8} lg={5}>
            <Box>
              <WelcomeGraphic />
              {logo && <Logo className={classes.logo} />}
              <Typography variant="body1" color="primary">
                {copy.message}
              </Typography>
              <Box mt={{ xs: 2, lg: 4 }}>
                <Button variant="contained" color="primary" onClick={handleComplete} fullWidth>
                  {copy.completeCta}
                </Button>
              </Box>
              <Typography variant="body2">
                {copy.help}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default WelcomePage
