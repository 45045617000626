const AFAFCopy: LanguageCopy = {
  content: {
    "bad-reception": "Episode 1",
    baking: "Episode 2",
    campfire: "Episode 3",
    "flying-lessons": "Episode 4",
    "garage-band": "Episode 5",
    "hangout-spot": "Episode 6",
    hungry: "Episode 7",
    message: "Episode 8",
    neighbor: "Episode 9",
    "nice-day": "Episode 10",
    record: "Episode 11",
    "snow-clone": "Episode 12",
    switch: "Episode 13",
    "wishing-well": "Episode 14",
    workout: "Episode 15",
  },
  contentPage: {
    heading: "Avonture van Roland en Robin",
  },
  layout: {
    title: "CQMF149G2203",
    sidebarHeading: "Videos",
    privacyPolicy: "Privaatheidsbeleid",
    termsOfUse: "Gebruiksbepalings",
    copyright: "© Jumo Health USA, Inc. Alle regte voorbehou..",
  },
  welcomePage: {
    heading: "Welcome!",
    message:
      "Dankie dat u die CQMF149G2203 oorweeg. Dit is danksy mense soos jy dat ons as ’n gemeenskap kan saamstaan en lewensreddende medisyne kan help skep.",
    completeCta: "Voer in",
    help: "Hulp nodig? Kontak jou plaaslike gesondheidsorgverskaffer."
  },
}

export default AFAFCopy
