import useCopy from "./useCopy"

const useItemTitle = (item: ContentItem) => {
  const copy = useCopy()

  if (item.title) return item.title

  const content = copy.content || {}

  if (!content[item.id]) {
    throw new Error(`Unable to find translated title for item ${item.id}`)
  }

  return content[item.id]
}

export default useItemTitle
