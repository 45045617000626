const HUHUCopy: LanguageCopy = {
  content: {
    "bad-reception": "1 rész",
    baking: "2 rész",
    campfire: "3 rész",
    "flying-lessons": "4 rész",
    "garage-band": "5 rész",
    "hangout-spot": "6 rész",
    hungry: "7 rész",
    message: "8 rész",
    neighbor: "9 rész",
    "nice-day": "10 rész",
    record: "11 rész",
    "snow-clone": "12 rész",
    switch: "13 rész",
    "wishing-well": "14 rész",
    workout: "15 rész",
  },
  layout: {
    title: "CQMF149G2203",
    sidebarHeading: "Epizódok",
    privacyPolicy: "Személyes adatok védelmére vonatkozó nyilatkozat",
    termsOfUse: "Használati feltételek",
    copyright: "© Jumo Health USA, Inc. All Rights Reserved.",
  },
  contentPage: {
    heading: "Roland és Robin kalandjai",
  },
  welcomePage: {
    heading: "Welcome HU",
    message:
      "Köszönjük, hogy megfontolja a CQMF149G2203 termék alkalmazását. Az Önhöz hasonló emberek miatt tudunk közösségként összefogni és segíteni az életmentő gyógyszerek létrehozásában.",
    completeCta: "Belépés",
    help: "Segítségre van szüksége? Kérjük, lépjen kapcsolatba a helyi egészségügyi szolgáltatójával."
  },
}

export default HUHUCopy
