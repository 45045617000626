import { useContext } from "react";
import { WebsiteContext } from "../Website";

const useWebsiteContext = () => {
  const ctx = useContext(WebsiteContext);
  if (!ctx) {
    throw new Error("Website Context was used before it was initialized");
  }

  return ctx;
};

export default useWebsiteContext;
