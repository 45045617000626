import { createMuiTheme } from "@material-ui/core"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"

declare module "@material-ui/core/styles/createBreakpoints" {
  interface BreakpointOverrides {
    xs: true
    sm: false
    md: true
    lg: true
    xl: true
  }
}

export const defaultBreakpointValues = {
  xs: 0,
  md: 700, // Tablet portrait
  lg: 1024, // Tablet landscape
  xl: 1140, // Desktop
}

const breakpoints = createBreakpoints({
  values: defaultBreakpointValues,
})

const theme = createMuiTheme({
  breakpoints,
  palette: {
    action: {
      hover: "#f2f2f2",
    },
    primary: {
      light: "#2B91E1",
      main: "#0460A9",
    },
    grey: {
      50: "#7C7B7B",
      100: "#f2f2f2",
    },
  },
  typography: {
    fontFamily: "lato",
    h1: {},
    h4: {
      fontFamily: "raleway",
      fontSize: "1.125rem",
      fontWeight: 400,
      letterSpacing: "0.1em",
    },
    body1: {
      lineHeight: "1.7em",
    },
    caption: {
      fontSize: "0.875rem",
      letterSpacing: "0.04em",
    },
    body2: {
      fontSize: "0.875rem",
      letterSpacing: "0.04em",
      color: "#7C7B7B",
      paddingTop: "32px",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
        fontSize: "11px",
        lineHeight: "11px",
        letterSpacing: "0.38em",
        padding: "22px 8px",
      },
      containedPrimary: {
        backgroundColor: "#000000",
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    MuiListItemIcon: {
      root: {
        color: "#000000",
        minWidth: 40,
      },
    },
    MuiListItemText: {
      root: {},
      primary: {
        textTransform: "uppercase",
        letterSpacing: ".38em",
        fontWeight: 600,
        fontSize: "11px",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "11px",
        fontWeight: 600,
        textTransform: "uppercase",
        letterSpacing: "0.38em",
      },
    },
    MuiTypography: {
      root: {
        whiteSpace: "pre-wrap",
      },
    },
  },
})

export const isPhone = () => window.innerWidth < theme.breakpoints.values.md
export const isTablet = () => !isPhone() && window.innerWidth < theme.breakpoints.values.lg
export const isDesktop = () => window.innerWidth >= theme.breakpoints.values.lg

export default theme
