import {
  Box,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core"
import useContent from "framework/hooks/useContent"
import SecurityOutlinedIcon from "@material-ui/icons/SecurityOutlined"
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined"
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined"
import VideoLibraryOutlinedIcon from "@material-ui/icons/VideoLibraryOutlined"
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay"
import React from "react"
import useCopy from "framework/hooks/useCopy"
import { Link, useRouteMatch } from "react-router-dom"
import useWebsiteContext from "framework/hooks/useWebsiteContext"
import { ReactComponent as Logo } from "assets/logo.svg"
import useItemTitle from "framework/hooks/useItemTitle"
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline"
import clsx from "clsx"

const styles = makeStyles((theme) => ({
  top: {
    padding: "0",
    [theme.breakpoints.up("lg")]: {
      padding: "24px 0",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "40px 0",
    },
  },
  logo: {
    display: "none",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      display: "block",
      margin: "0 0 24px 0",
      width: 180,
      height: 30,
    },
    [theme.breakpoints.up("xl")]: {
      margin: "0 0 40px 0",
    },
  },
  mainList: {
    padding: "0 16px",
  },
  listItem: {
    // padding: "8px",
    // margin: "0 16px",
  },
  bottom: {
    marginTop: "auto",
    padding: "16px 0",
    paddingLeft: 16,
    paddingRight: 16,
  },
  closeMobileMenu: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  subItemText: {
    color: theme.palette.grey[50],
    textTransform: "none",
    letterSpacing: ".04em",
    fontSize: ".875rem",
    fontWeight: 400,
  },
  subItemIcon: {
    color: theme.palette.grey[50],
  },
  subItemIconActive: {
    color: theme.palette.primary.main,
  },
  activeItem: {
    "&:before": {
      content: "''",
      display: "block",
      position: "absolute",
      width: 4,
      height: "100%",
      left: -16,
      backgroundColor: "#E74A21",
    },
  },
  activeSubItem: {
    backgroundColor: "#F3FAFF",
  },
}))
const SidebarItem = ({ item }: { item: ContentItem }) => {
  const classes = styles()
  const title = useItemTitle(item)
  const { activeContentItem, setActiveContentItem, toggleMobileMenu } = useWebsiteContext()
  const handleClick = () => {
    setActiveContentItem(item)
    toggleMobileMenu()
  }

  const isActive = activeContentItem?.id === item.id
  const Icon = isActive ? PlayCircleOutlineIcon : PlaylistPlayIcon

  return (
    <ListItem
      button
      to="/"
      key={item.id}
      component={Link}
      onClick={handleClick}
      className={clsx({
        [classes.activeSubItem]: isActive,
      })}
    >
      <ListItemIcon
        className={clsx({
          [classes.subItemIcon]: true,
          [classes.subItemIconActive]: isActive,
        })}
      >
        <Icon />
      </ListItemIcon>
      <ListItemText classes={{ primary: classes.subItemText }}>{title}</ListItemText>
    </ListItem>
  )
}

type RouteParams = {
  path: string
}
const Sidebar = () => {
  const { items } = useContent()
  const { layout: copy } = useCopy()
  const { toggleMobileMenu } = useWebsiteContext()
  const classes = styles()
  const routeMatch = useRouteMatch<RouteParams>("/:path?")

  if (!routeMatch) {
    throw new Error("Uknown route in sidebar")
  }

  const { path } = routeMatch.params

  return (
    <>
      <Box className={classes.top}>
        <IconButton className={classes.closeMobileMenu} onClick={toggleMobileMenu}>
          <CloseOutlinedIcon />
        </IconButton>
        <Container>
          <Logo className={classes.logo} />
        </Container>

        <List className={classes.mainList}>
          <ListItem
            to="/"
            component={Link}
            className={clsx({
              [classes.listItem]: true,
              [classes.activeItem]: !path,
            })}
            onClick={toggleMobileMenu}
          >
            <ListItemIcon>
              <VideoLibraryOutlinedIcon />
            </ListItemIcon>
            <ListItemText>{copy.sidebarHeading}</ListItemText>
          </ListItem>
          {items.map((item) => (
            <SidebarItem key={item.id} item={item} />
          ))}
        </List>
      </Box>

      <Box className={classes.bottom}>
        <List>
          <ListItem
            button
            to="/privacy"
            component={Link}
            onClick={toggleMobileMenu}
            className={clsx({
              [classes.activeItem]: path === "privacy",
            })}
          >
            <ListItemIcon>
              <SecurityOutlinedIcon />
            </ListItemIcon>
            <ListItemText>{copy.privacyPolicy}</ListItemText>
          </ListItem>
          <ListItem
            button
            to="/terms"
            component={Link}
            onClick={toggleMobileMenu}
            className={clsx({
              [classes.listItem]: true,
              [classes.activeItem]: path === "terms",
            })}
          >
            <ListItemIcon>
              <ListAltOutlinedIcon />
            </ListItemIcon>
            <ListItemText>{copy.termsOfUse}</ListItemText>
          </ListItem>
        </List>
        <Box px={3}>
          <Typography variant="caption">{copy.copyright}</Typography>
        </Box>
      </Box>
    </>
  )
}

export default Sidebar
