const USENCopy: LanguageCopy = {
  content: {
    "bad-reception": "Episode 1",
    baking: "Episode 2",
    campfire: "Episode 3",
    "flying-lessons": "Episode 4",
    "garage-band": "Episode 5",
    "hangout-spot": "Episode 6",
    hungry: "Episode 7",
    message: "Episode 8",
    neighbor: "Episode 9",
    "nice-day": "Episode 10",
    record: "Episode 11",
    "snow-clone": "Episode 12",
    switch: "Episode 13",
    "wishing-well": "Episode 14",
    workout: "Episode 15",
  },
  contentPage: {
    heading: "Adventures of Roland & Robin",
  },
  layout: {
    title: "CQMF149G2203",
    sidebarHeading: "Videos",
    privacyPolicy: "Privacy Policy",
    termsOfUse: "Terms of Use",
    copyright: "© Jumo Health USA, Inc.\nAll Rights Reserved.",
  },
  welcomePage: {
    heading: "Welcome!",
    message:
      "Thank you for considering the  CQMF149G2203. It is because of people like yourself that we are able to come together as a community and help create life saving medicines. ",
    completeCta: "Continue",
    help: "Need help? Please contact your local health provider."
  },
}

export default USENCopy
