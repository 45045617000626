import { Box, Button, makeStyles, MenuItem, MenuList, Popover } from "@material-ui/core"
import useWebsiteContext from "framework/hooks/useWebsiteContext"
import LanguageIcon from "@material-ui/icons/Language"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import React, { useRef } from "react"
import clsx from "clsx"

const styles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 3,
    right: 8,
  },
  button: {
    color: theme.palette.common.white,
    fontFamily: "raleway",
    fontSize: ".6875rem",
    fontWeight: 600,
    letterSpacing: ".38em",
    position: "relative",
  },
  popoverPaper: {
    borderRadius: 0,
  },
  active: {
    color: theme.palette.primary.main,
  },
}))

const LanguageMenu = () => {
  const classes = styles()
  const { i18n, language, languageMenuOpen, toggleLanguageMenu, setLanguage } = useWebsiteContext()
  const anchorRef = useRef(null)

  return (
    <Box className={classes.root}>
      <Button
        variant="text"
        className={classes.button}
        ref={anchorRef}
        onClick={toggleLanguageMenu}
        startIcon={<LanguageIcon />}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {language.label}
      </Button>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={languageMenuOpen}
        onClose={toggleLanguageMenu}
        classes={{ paper: classes.popoverPaper }}
      >
        <MenuList>
          {i18n.languages?.map((lang) => (
            <MenuItem
              onClick={() => {
                setLanguage(lang)
                toggleLanguageMenu()
              }}
              key={lang.locale}
              className={clsx({
                [classes.active]: lang === language,
              })}
            >
              {lang.label}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </Box>
  )
}

export default LanguageMenu
