import { Card, CardActionArea, CardMedia, makeStyles, Typography } from "@material-ui/core"
import useItemTitle from "framework/hooks/useItemTitle"
import useWebsiteContext from "framework/hooks/useWebsiteContext"
import React from "react"
import PlayCircleOutlineOutlinedIcon from "@material-ui/icons/PlayCircleOutlineOutlined"
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined"
import clsx from "clsx"

type Props = {
  item: ContentItem
}

const styles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    boxShadow: "none",
    borderRadius: 0,
    color: theme.palette.common.white,
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      borderRadius: 10,
    },
    "& .drape": {
      opacity: 0,
      transition: "opacity .2s",
    },
    "&:hover .drape": {
      opacity: 1,
    },
  },
  active: {
    "& .drape": {
      opacity: 1,
    },
  },
  actionArea: {
    transition: "background-color .3s",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  media: {
    paddingBottom: "56.25%",
  },
  title: {
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
    letterSpacing: 0.6,
    padding: 8,
  },
  icon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    fontSize: "58px",
    lineHeight: "1em",
    marginLeft: -29,
    marginTop: -29,
  },
  drape: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    paddingBottom: "56.25%",
    backgroundColor: "rgba(0,0,0,0.26)",
    zIndex: 2,
    pointerEvents: "none",
  },
}))

const ContentThumb = ({ item }: Props) => {
  const classes = styles()
  const title = useItemTitle(item)
  const { activeContentItem, setActiveContentItem } = useWebsiteContext()
  const handleClick = () => {
    setActiveContentItem(item)
  }

  const isActive = item.id === activeContentItem.id
  const Icon = isActive ? PlayArrowOutlinedIcon : PlayCircleOutlineOutlinedIcon

  return (
    <Card
      className={clsx({
        [classes.root]: true,
        [classes.active]: isActive,
      })}
    >
      <div
        className={clsx({
          [classes.drape]: true,
          drape: true,
        })}
      >
        <Icon className={clsx(classes.icon, "icon")} />
      </div>
      <CardActionArea className={classes.actionArea} onClick={handleClick}>
        <CardMedia className={classes.media} image={item.thumbnail} />
        <Typography className={classes.title}>{title}</Typography>
      </CardActionArea>
    </Card>
  )
}

export default ContentThumb
