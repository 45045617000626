import React from "react";

type Props = {
  children: React.ReactNode;
};

const RequiresNoWelcomePage = ({ children }: Props) => {
  return <>{children}</>;
};

export default RequiresNoWelcomePage;
