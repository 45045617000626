import React from "react";
import useWebsiteContext from "../hooks/useWebsiteContext";
import WelcomePage from "../pages/WelcomePage";

type Props = {
  children: React.ReactNode;
};

const RequiresWelcomePage = ({ children }: Props) => {
  const { welcomePageCompleted } = useWebsiteContext();

  if (!welcomePageCompleted) {
    return <WelcomePage />;
  }

  return <>{children}</>;
};

export default RequiresWelcomePage;
