import { useContext } from "react";
import { WebsiteContext } from "../Website";

const useAuth = () => {
  const ctx = useContext(WebsiteContext);
  if (!ctx) {
    throw new Error("Website Content was used before it was initialized");
  }
  const { isAuthenticated } = ctx;

  return {
    isAuthenticated,
  };
};

export default useAuth;
