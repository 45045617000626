import { Box, Container, Grid, makeStyles, Typography } from "@material-ui/core"
import ContentThumb from "framework/ContentThumb"
import useContent from "framework/hooks/useContent"
import useCopy from "framework/hooks/useCopy"
import useItemTitle from "framework/hooks/useItemTitle"
import useWebsiteContext from "framework/hooks/useWebsiteContext"
import Video from "framework/Video"
import React, { useEffect } from "react"

const useStyles = makeStyles((theme) => ({
  top: {
    paddingBottom: 24,
    [theme.breakpoints.up("xl")]: {
      paddingBottom: 40,
    },
  },
  topHeading: {
    lineHeight: "24px",
    [theme.breakpoints.up("xl")]: {
      lineHeight: "40px",
    },
  },
}))

const ContentPage = () => {
  const { items } = useContent()
  const { activeContentItem } = useWebsiteContext()
  const copy = useCopy()
  const classes = useStyles()
  const activeItemTitle = useItemTitle(activeContentItem)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [activeContentItem])

  useEffect(() => {
    gtag("event", "page_view", {
      page_title: "content",
    })
  })

  if (!activeContentItem) {
    return null
  }

  return (
    <Box>
      <Container maxWidth={false}>
        <Box className={classes.top}>
          <Typography variant="h4" className={classes.topHeading}>
            {copy.contentPage.heading}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Video item={activeContentItem} />
            <Box mt={0.5}>
              <Typography variant="body1">{activeItemTitle}</Typography>
            </Box>
          </Grid>
          {items.map((item) => {
            if (item.id === activeContentItem.id) {
              return null
            }
            return (
              <Grid item xs={12} md={6} lg={4} xl={3} key={item.id}>
                <ContentThumb item={item} />
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </Box>
  )
}

export default ContentPage
