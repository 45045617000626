import React, { useEffect, useState } from "react"
import { Box, Container, Drawer, makeStyles } from "@material-ui/core"
import Sidebar from "framework/Sidebar"
import { isDesktop } from "theme"
import TopBar from "framework/TopBar"
import useWebsiteContext from "framework/hooks/useWebsiteContext"
import LanguageMenu from "framework/LanguageMenu"
import clsx from "clsx"
import LanguageDrawer from "framework/LanguageDrawer"
import useCopy from "framework/hooks/useCopy"

export const drawerWidth = 256

const styles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: drawerWidth,
    },
  },
  drawer: {
    flexShrink: 0,
    width: drawerWidth,
  },
  drawerPaper: {
    border: "none",
    width: drawerWidth,
  },
  content: {
    backgroundRepeat: "repeat-y",
    backgroundSize: "32px",
    backgroundPosition: "-18px 0",
    color: "white",
    minHeight: "100vh",
    padding: "80px 0 24px 0",
    [theme.breakpoints.up("md")]: {
      backgroundSize: "65px",
      backgroundPosition: "0 0",
      padding: "80px 100px 24px 80px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "24px 64px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "40px 64px",
    },
  },
  contentBackgroundPrimary: {
    backgroundColor: "#0460A9",
    backgroundImage: "url(images/bg-primary.png)",
    color: "white",
  },
  contentBackgroundSecondary: {
    backgroundColor: theme.palette.common.white,
    backgroundImage: "url(images/bg-secondary.png)",
    color: theme.palette.primary.main,
  },
}))

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => {
  const classes = styles()
  const { mobileMenuOpen, toggleMobileMenu, background } = useWebsiteContext()
  const [, setWindowWidth] = useState(window.innerWidth)
  const copy = useCopy()
  const title = copy.layout.title

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener("resize", onResize)
    document.title = title
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [title])

  const LanguageSelector = isDesktop() ? LanguageMenu : LanguageDrawer

  return (
    <Box className={classes.root}>
      <Drawer
        variant={isDesktop() ? "permanent" : "temporary"}
        open={isDesktop() || mobileMenuOpen}
        onClose={toggleMobileMenu}
        anchor="left"
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
      >
        <Sidebar />
      </Drawer>
      <TopBar />

      <Box
        className={clsx(classes.content, {
          [classes.contentBackgroundPrimary]: background === "primary",
          [classes.contentBackgroundSecondary]: background === "secondary",
        })}
      >
        <Container style={{ position: "relative" }} maxWidth={false}>
          <LanguageSelector />
        </Container>
        {children}
      </Box>
    </Box>
  )
}

export default Layout
